<template>
  <div id="app">
    <b-container fluid>
      <b-row align-v="stretch">
        <b-col xl="5" class="left-container d-none d-xl-block">
          <div class="mini-container">
            <welcome-card></welcome-card>
          </div>
          <policies></policies>
        </b-col>
        <b-col col xl="7" class="right-container d-md-block">
          <div class="tac-title">Terms and conditions</div>
          <div class="tac-section">
            <div class="tac-subtitle">1. Who May Use the Services</div>
            You may use the Services only if you agree to form a binding
            contract with Dib8 and are not a person barred from receiving
            services under the laws of the applicable jurisdiction. In any case,
            you must be at least 13 years old, to use the Services. If you are
            accepting these Terms and using the Services on behalf of a company,
            organization, government, or other legal entity, you represent and
            warrant that you are authorized to do so and have the authority to
            bind such entity to these Terms, in which case the words “you” and
            “your” as used in these Terms shall refer to such entity.
          </div>
          <div class="tac-section">
            <div class="tac-subtitle">2. Privacy</div>
            Our Privacy Policy (<a href="https://www.dib8.app/privacy">https://www.dib8.app/privacy</a>) describes how we
            handle the information you provide to us when you use our Services.
            You understand that through your use of the Services you consent to
            the collection and use (as set forth in the Privacy Policy) of this
            information, including the transfer of this information to the
            United States, Germany, and/or other countries for storage,
            processing and use by Dib8 and its affiliates.
          </div>
          <div class="tac-section">
            <div class="tac-subtitle">3. Content on the Services</div>
            You are responsible for your use of the Services and for any Content
            you provide, including compliance with applicable laws, rules, and
            regulations. You should only provide Content that you are
            comfortable sharing with others. <br />
            <br />
            Any use or reliance on any Content or materials posted via the
            Services or obtained by you through the Services is at your own
            risk. We do not endorse, support, represent or guarantee the
            completeness, truthfulness, accuracy, or reliability of any Content
            or communications posted via the Services or endorse any opinions
            expressed via the Services. You understand that by using the
            Services, you may be exposed to Content that might be offensive,
            harmful, inaccurate or otherwise inappropriate, or in some cases,
            postings that have been mislabeled or are otherwise deceptive. All
            Content is the sole responsibility of the person who originated such
            Content. We may not monitor or control the Content posted via the
            Services and, we cannot take responsibility for such Content.We
            reserve the right to remove Content that violates the User
            Agreement, including for example, copyright or trademark violations
            or other intellectual property misappropriation, impersonation,
            unlawful conduct, or harassment. <br />
            <br />
            If you believe that your Content has been copied in a way that
            constitutes copyright infringement, please report this by contacting
            our designated copyright agent at: <br />
            Email: copyright@dib8.app <br />
            Your Rights and Grant of Rights in the Content You retain your
            rights to any Content you submit, post or display on or through the
            Services. What’s yours is yours — you own your Content (and your
            incorporated audio, photos and videos are considered part of the
            Content). <br />
            <br />By submitting, posting or displaying Content on or through the
            Services, you grant us a worldwide, non-exclusive, royalty-free
            license (with the right to sublicense) to use, copy, reproduce,
            process, adapt, modify, publish, transmit, display and distribute
            such Content in any and all media or distribution methods now known
            or later developed (for clarity, these rights include, for example,
            curating, transforming, and translating). This license authorize us
            to make your Content available to the rest of the world and to let
            others do the same. You agree that this license includes the right
            for Dib8 to provide, promote, and improve the Services and to make
            Content submitted to or through the Services available to other
            companies, organisations or individuals for the syndication,
            broadcast, distribution, Retweet, promotion or publication of such
            Content on other media and services, subject to our terms and
            conditions for such Content use. Such additional uses by Dib8, or
            other companies, organisations or individuals, is made with no
            compensation paid to you with respect to the Content that you
            submit, post, transmit or otherwise make available through the
            Services as the use of the Services by you is hereby agreed as being
            sufficient compensation for the Content and grant of rights herein.
            <br />
            <br />
            Dib8 has an evolving set of rules for how ecosystem partners can
            interact with your Content on the Services. These rules exist to
            enable an open ecosystem with your rights in mind. You understand
            that we may modify or adapt your Content as it is distributed,
            syndicated, published, or broadcast by us and our partners and/or
            make changes to your Content in order to adapt the Content to
            different media. <br />
            <br />
            You represent and warrant that you have, or have obtained, all
            rights, licenses, consents, permissions, power and/or authority
            necessary to grant the rights granted herein for any Content that
            you submit, post or display on or through the Services. You agree
            that such Content will not contain material subject to copyright or
            other proprietary rights, unless you have necessary permission or
            are otherwise legally entitled to post the material and to grant
            Dib8 the license described above.
          </div>
          <div class="tac-section">
            <div class="tac-subtitle">4. Using the Services</div>
            Please review the Dib8 Privacy Policy, which are part of the User
            Agreement and outline what is prohibited on the Services. You may
            use the Services only in compliance with these Terms and all
            applicable laws, rules and regulations.<br />
            <br />
            Our Services evolve constantly. As such, the Services may change
            from time to time, at our discretion. We may stop (permanently or
            temporarily) providing the Services or any features within the
            Services to you or to users generally. We also retain the right to
            create limits on use and storage at our sole discretion at any time.
            We may also remove or refuse to distribute any Content on the
            Services, limit distribution or visibility of any Content on the
            service, suspend or terminate users, and reclaim usernames without
            liability to you. <br />
            <br />
            In consideration for Dib8 granting you access to and use of the
            Services, you agree that Dib8 and its third-party providers and
            partners may place advertising on the Services or in connection with
            the display of Content or information from the Services whether
            submitted by you or others. You also agree not to misuse our
            Services, for example, by interfering with them or accessing them
            using a method other than the interface and the instructions that we
            provide. You agree that you will not work around any technical
            limitations in the software provided to you as part of the Services,
            or reverse engineer, decompile or disassemble the software, except
            and only to the extent that applicable law expressly permits. You
            may not do any of the following while accessing or using the
            Services: (i) access, tamper with, or use non-public areas of the
            Services, Dib8’s computer systems, or the technical delivery systems
            of Dib8’s providers; (ii) probe, scan, or test the vulnerability of
            any system or network or breach or circumvent any security or
            authentication measures; (iii) access or search or attempt to access
            or search the Services by any means (automated or otherwise) other
            than through our currently available, published interfaces that are
            provided by Dib8 (and only pursuant to the applicable terms and
            conditions), unless you have been specifically allowed to do so in a
            separate agreement with Dib8 (NOTE: crawling the Services is
            permissible if done in accordance with the provisions of the
            robots.txt file, however, scraping the Services without the prior
            consent of Dib8 is expressly prohibited); (iv) forge any TCP/IP
            packet header or any part of the header information in any email or
            posting, or in any way use the Services to send altered, deceptive
            or false source-identifying information; or (v) interfere with, or
            disrupt, (or attempt to do so), the access of any user, host or
            network, including, without limitation, sending a virus,
            overloading, flooding, spamming, mail-bombing the Services, or by
            scripting the creation of Content in such a manner as to interfere
            with or create an undue burden on the Services. We also reserve the
            right to access, read, preserve, and disclose any information as we
            reasonably believe is necessary to (i) satisfy any applicable law,
            regulation, legal process or governmental request, (ii) enforce the
            Terms, including investigation of potential violations hereof, (iii)
            detect, prevent, or otherwise address fraud, security or technical
            issues, (iv) respond to user support requests, or (v) protect the
            rights, property or safety of Dib8, its users and the public. Dib8
            does not disclose personally-identifying information to third
            parties except in accordance with our Privacy Policy. <br />
            <br />
            Dib8 may offer certain services or features for which additional
            terms and conditions may apply in connection with your use of those
            services. By using or paying for any of these additional services,
            you agree to any additional terms applicable to those services, and
            those additional terms become part of our agreement with you. If any
            of the applicable additional terms conflict with these Terms, the
            additional terms will prevail while you are using those services to
            which they apply. <br />
            <br />Your Account <br />You may need to create an account to use
            some of our Services. You are responsible for safeguarding your
            account. We cannot and will not be liable for any loss or damage
            arising from your failure to comply with the above. <br />
            <br />You can control most communications from the Services. We may
            need to provide you with certain communications, such as service
            announcements and administrative messages. These communications are
            considered part of the Services and your account, and you may not be
            able to opt-out from receiving them. If you added your phone number
            to your account and you later change or deactivate that phone
            number, you must update your account information to help prevent us
            from communicating with anyone who acquires your old number. <br />
            <br />Your License to Use the Services <br />Dib8 gives you a
            personal, worldwide, royalty-free, non-assignable and non-exclusive
            license to use the software provided to you as part of the Services.
            This license has the sole purpose of enabling you to use and enjoy
            the benefit of the Services as provided by Dib8, in the manner
            permitted by these Terms. <br />
            <br />The Services are protected by copyright, trademark, and other
            laws of both the United States and other countries. Nothing in the
            Terms gives you a right to use the Dib8 name or any of the Dib8
            trademarks, logos, domain names, other distinctive brand features,
            and other proprietary rights. All right, title, and interest in and
            to the Services (excluding Content provided by users) are and will
            remain the exclusive property of Dib8 and its licensors. Any
            feedback, comments, or suggestions you may provide regarding Dib8,
            or the Services is entirely voluntary and we will be free to use
            such feedback, comments or suggestions as we see fit and without any
            obligation to you. Ending These Terms <br />
            You may end your legal agreement with Dib8 at any time by
            deactivating your accounts and discontinuing your use of the
            Services. <br />
            <br />
            We may suspend or terminate your account or cease providing you with
            all or part of the Services at any time for any or no reason,
            including, but not limited to, if we reasonably believe: (i) you
            have violated these Terms or the Dib8 Rules and Policies (ii) you
            create risk or possible legal exposure for us; (iii) your account
            should be removed due to unlawful conduct, (iv) your account should
            be removed due to prolonged inactivity; or (v) our provision of the
            Services to you is no longer commercially viable. We will make
            reasonable efforts to notify you the next time you attempt to access
            your account. In all such cases, the Terms shall terminate,
            including, without limitation, your license to use the Services,
            except that the following sections shall continue to apply: 2, 3, 5,
            and 6. If you believe your account was terminated in error you can
            email us at: accounts@dib8.app. For the avoidance of doubt, these
            Terms survive the deactivation or termination of your account.
          </div>
          <div class="tac-section">
            <div class="tac-subtitle">
              5. Disclaimers and Limitations of Liability
            </div>
            The Services are Available "AS-IS" <br />Your access to and use of
            the Services or any Content are at your own risk. You understand and
            agree that the Services are provided to you on an “AS IS” and “AS
            AVAILABLE” basis. The “Dib8 Entities” refers to Dib8, its parents,
            affiliates, related companies, officers, directors, employees,
            agents, representatives, partners, and licensors. Without limiting
            the foregoing, to the maximum extent permitted under applicable law,
            THE Dib8 ENTITIES DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER
            EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NON-INFRINGEMENT. The Dib8 Entities make no warranty or
            representation and disclaim all responsibility and liability for:
            (i) the completeness, accuracy, availability, timeliness, security
            or reliability of the Services or any Content; (ii) any harm to your
            computer system, loss of data, or other harm that results from your
            access to or use of the Services or any Content; (iii) the deletion
            of, or the failure to store or to transmit, any Content and other
            communications maintained by the Services; and (iv) whether the
            Services will meet your requirements or be available on an
            uninterrupted, secure, or error-free basis. No advice or
            information, whether oral or written, obtained from the Dib8
            Entities or through the Services, will create any warranty or
            representation not expressly made herein. <br />
            <br />
            Limitation of Liability TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, THE Dib8 ENTITIES SHALL NOT BE LIABLE FOR ANY
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR
            ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
            INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
            LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO
            ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD
            PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY,
            OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii)
            ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS,
            USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO EVENT
            SHALL THE AGGREGATE LIABILITY OF THE Dib8 ENTITIES EXCEED THE
            GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE AMOUNT YOU
            PAID Dib8, IF ANY, IN THE PAST SIX MONTHS FOR THE SERVICES GIVING
            RISE TO THE CLAIM. THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO
            ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT,
            STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR
            NOT THE Dib8 ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY
            SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE
            FAILED OF ITS ESSENTIAL PURPOSE.
          </div>
          <div class="tac-section">
            <div class="tac-subtitle">6. General</div>
            We may revise these Terms from time to time. The changes will not be
            retroactive, and the most current version of the Terms, which will
            always be at <a href="https://www.dib8.app/tos">https://www.dib8.app/tos</a>, will govern our relationship with you.
            We will try to notify you of material revisions, for example via a
            service notification or an email to the email associated with your
            account. By continuing to access or use the Services after those
            revisions become effective, you agree to be bound by the revised
            Terms. <br />
            <br />The laws of the State of California, excluding its choice of
            law provisions, will govern these Terms and any dispute that arises
            between you and Dib8. All disputes related to these Terms or the
            Services will be brought solely in the federal or state courts
            located in Cluj-Napoca, Cluj, Romania, and you consent to personal
            jurisdiction and waive any objection as to inconvenient forum.
            <br />
            <br />If you are a federal, state, or local government entity in the
            United States using the Services in your official capacity and
            legally unable to accept the controlling law, jurisdiction or venue
            clauses above, then those clauses do not apply to you. For such U.S.
            federal government entities, these Terms and any action related
            thereto will be governed by the laws of the United States of America
            (without reference to conflict of laws) and, in the absence of
            federal law and to the extent permitted under federal law, the laws
            of the State of California (excluding choice of law). <br />
            <br />In the event that any provision of these Terms is held to be
            invalid or unenforceable, then that provision will be limited or
            eliminated to the minimum extent necessary, and the remaining
            provisions of these Terms will remain in full force and effect.
            Dib8’s failure to enforce any right or provision of these Terms will
            not be deemed a waiver of such right or provision. <br />
            <br />These Terms are an agreement between you and Overtex Core
            Solutions SRL, Inc., Str. Alexandru Vaida Voevod 59, Cluj-Napoca,
            Romania. If you have any questions about these Terms, please contact
            us. <br />
            <br />Effective:June 10, 2022 <br />
            <br />
            Dib8 Terms of Service<br />
            If you live in the European Union, EFTA States, or the United
            Kingdom<br />
            <br />
            These Terms of Service (“Terms”) govern your access to and use of
            our services, including our various websites, SMS, APIs, email
            notifications, applications, buttons, widgets, ads, commerce
            services, and our other covered services that link to these Terms
            (collectively, the “Services”), and any information, text, links,
            graphics, photos, audio, videos, or other materials or arrangements
            of materials uploaded, downloaded or appearing on the Services
            (collectively referred to as “Content”). By using the Services you
            agree to be bound by these Terms. <br />
            <br />1. Who May Use the Services <br />2. Privacy <br />3. Content
            on the Services <br />
            4. Using the Services <br />
            5. Limitations of Liability <br />
            6. General <br />
            <br />
            <div class="tac-section">
              <div class="tac-subtitle">1. Who May Use the Services</div>
              You may use the Services only if you agree to form a binding
              contract with Dib8 and are not a person barred from receiving
              services under the laws of the applicable jurisdiction. In any
              case, you must be at least 13 years old, or in the case of
              Periscope 16 years old, to use the Services. If you are accepting
              these Terms and using the Services on behalf of a company,
              organization, government, or other legal entity, you represent and
              warrant that you are authorized to do so and have the authority to
              bind such entity to these Terms, in which case the words “you” and
              “your” as used in these Terms shall refer to such entity.
            </div>
            <div class="tac-section">
              <div class="tac-subtitle">2. Privacy</div>
              Our Privacy Policy (<a href="https://www.dib8.app/privacy">https://www.dib8.app/privacy</a>) describes how
              we handle the information you provide to us when you use our
              Services. You understand that through your use of the Services you
              consent to the collection and use (as set forth in the Privacy
              Policy) of this information, including the transfer of this
              information to the United States, Ireland, and/or other countries
              for storage, processing and use by Dib8 and its affiliates.
            </div>
            <div class="tac-section">
              <div class="tac-subtitle">3. Content on the Services</div>
              You are responsible for your use of the Services and for any
              Content you provide, including compliance with applicable laws,
              rules, and regulations. You should only provide Content that you
              are comfortable sharing with others. <br /><br />Any use or
              reliance on any Content or materials posted via the Services or
              obtained by you through the Services is at your own risk. We do
              not endorse, support, represent or guarantee the completeness,
              truthfulness, accuracy, or reliability of any Content or
              communications posted via the Services or endorse any opinions
              expressed via the Services. You understand that by using the
              Services, you may be exposed to Content that might be offensive,
              harmful, inaccurate or otherwise inappropriate, or in some cases,
              postings that have been mislabeled or are otherwise deceptive. All
              Content is the sole responsibility of the person who originated
              such Content. We may not monitor or control the Content posted via
              the Services and, we cannot take responsibility for such
              Content.<br /><br />
              We reserve the right to remove Content that violates the User
              Agreement, including for example, copyright or trademark
              violations or other intellectual property misappropriation,
              impersonation, unlawful conduct, or harassment. Information
              regarding specific policies and the process for reporting or
              appealing violations can be found in our Rules and Policies.
              <br /><br />If you believe that your Content has been copied in a
              way that constitutes copyright infringement, please contact our
              designated copyright agent at: <br /><br />
              Email: copyright@dib8.app <br />
              Your Rights and Grant of Rights in the Content <br />
              You retain your rights to any Content you submit, post or display
              on or through the Services. What’s yours is yours — you own your
              Content (and your incorporated audio, photos and videos are
              considered part of the Content).<br /><br />
              By submitting, posting or displaying Content on or through the
              Services, you grant us a worldwide, non-exclusive, royalty-free
              license (with the right to sublicense) to use, copy, reproduce,
              process, adapt, modify, publish, transmit, display and distribute
              such Content in any and all media or distribution methods now
              known or later developed (for clarity, these rights include, for
              example, curating, transforming, and translating). This license
              authorizes us to make your Content available to the rest of the
              world and to let others do the same. You agree that this license
              includes the right for Dib8 to provide, promote, and improve the
              Services and to make Content submitted to or through the Services
              available to other companies, organizations or individuals for the
              syndication, broadcast, distribution, Retweet, promotion or
              publication of such Content on other media and services, subject
              to our terms and conditions for such Content use. Such additional
              uses by Dib8, or other companies, organizations or individuals, is
              made with no compensation paid to you with respect to the Content
              that you submit, post, transmit or otherwise make available
              through the Services as the use of the Services by you is hereby
              agreed as being sufficient compensation for the Content and grant
              of rights herein. <br /><br />Dib8 has an evolving set of rules
              for how ecosystem partners can interact with your Content on the
              Services. These rules exist to enable an open ecosystem with your
              rights in mind. You understand that we may modify or adapt your
              Content as it is distributed, syndicated, published, or broadcast
              by us and our partners and/or make changes to your Content in
              order to adapt the Content to different media. <br /><br />You
              represent and warrant that you have, or have obtained, all rights,
              licenses, consents, permissions, power and/or authority necessary
              to grant the rights granted herein for any Content that you
              submit, post or display on or through the Services. You agree that
              such Content will not contain material subject to copyright or
              other proprietary rights, unless you have necessary permission or
              are otherwise legally entitled to post the material and to grant
              Dib8 the license described above.
            </div>
            <div class="tac-section">
              <div class="tac-subtitle">4. Using the Services</div>
              Please review the Dib8 Rules and Policies, which are part of the
              User Agreement and outline what is prohibited on the Services. You
              may use the Services only in compliance with these Terms and all
              applicable laws, rules and regulations. <br /><br />Our Services
              evolve constantly. As such, the Services may change from time to
              time, at our discretion. We may stop (permanently or temporarily)
              providing the Services or any features within the Services to you
              or to users generally. We also retain the right to create limits
              on use and storage at our sole discretion at any time. We may also
              remove or refuse to distribute any Content on the Services, limit
              distribution or visibility of any Content on the service, suspend
              or terminate users, and reclaim usernames without liability to
              you. <br /><br />In consideration for Dib8 granting you access to
              and use of the Services, you agree that Dib8 and its third-party
              providers and partners may place advertising on the Services or in
              connection with the display of Content or information from the
              Services whether submitted by you or others. You also agree not to
              misuse our Services, for example, by interfering with them or
              accessing them using a method other than the interface and the
              instructions that we provide. You agree that you will not work
              around any technical limitations in the software provided to you
              as part of the Services, or reverse engineer, decompile or
              disassemble the software, except and only to the extent that
              applicable law expressly permits. You may not do any of the
              following while accessing or using the Services: (i) access,
              tamper with, or use non-public areas of the Services, Dib8’s
              computer systems, or the technical delivery systems of Dib8’s
              providers; (ii) probe, scan, or test the vulnerability of any
              system or network or breach or circumvent any security or
              authentication measures; (iii) access or search or attempt to
              access or search the Services by any means (automated or
              otherwise) other than through our currently available, published
              interfaces that are provided by Dib8 (and only pursuant to the
              applicable terms and conditions), unless you have been
              specifically allowed to do so in a separate agreement with Dib8
              (NOTE: crawling the Services is permissible if done in accordance
              with the provisions of the robots.txt file, however, scraping the
              Services without the prior consent of Dib8 is expressly
              prohibited); (iv) forge any TCP/IP packet header or any part of
              the header information in any email or posting, or in any way use
              the Services to send altered, deceptive or false
              source-identifying information; or (v) interfere with, or disrupt,
              (or attempt to do so), the access of any user, host or network,
              including, without limitation, sending a virus, overloading,
              flooding, spamming, mail-bombing the Services, or by scripting the
              creation of Content in such a manner as to interfere with or
              create an undue burden on the Services. We also reserve the right
              to access, read, preserve, and disclose any information as we
              reasonably believe is necessary to (i) satisfy any applicable law,
              regulation, legal process or governmental request, (ii) enforce
              the Terms, including investigation of potential violations hereof,
              (iii) detect, prevent, or otherwise address fraud, security or
              technical issues, (iv) respond to user support requests, or (v)
              protect the rights, property or safety of Dib8, its users and the
              public. Dib8 does not disclose personally-identifying information
              to third parties except in accordance with our Privacy Policy.<br /><br />
              Dib8 may offer certain services or features for which additional
              terms and conditions may apply in connection with your use of
              those services. By using or paying for any of these additional
              services, you agree to any additional terms applicable to those
              services, and those additional terms become part of our agreement
              with you. If any of the applicable additional terms conflict with
              these Terms, the additional terms will prevail while you are using
              those services to which they apply. <br /><br />Your Account
              <br />You may need to create an account to use some of our
              Services. You are responsible for safeguarding your account, so
              use a strong password and limit its use to this account. We cannot
              and will not be liable for any loss or damage arising from your
              failure to comply with the above. <br /><br />You can control most
              communications from the Services. We may need to provide you with
              certain communications, such as service announcements and
              administrative messages. These communications are considered part
              of the Services and your account, and you may not be able to
              opt-out from receiving them. If you added your phone number to
              your account and you later change or deactivate that phone number,
              you must update your account information to help prevent us from
              communicating with anyone who acquires your old number. <br />Your
              License to Use the Services <br />
              Dib8 gives you a personal, worldwide, royalty-free, non-assignable
              and non-exclusive license to use the software provided to you as
              part of the Services. This license has the sole purpose of
              enabling you to use and enjoy the benefit of the Services as
              provided by Dib8, in the manner permitted by these Terms.
              <br /><br />The Services are protected by copyright, trademark,
              and other laws of both the United States and other countries.
              Nothing in the Terms gives you a right to use the Dib8 name or any
              of the Dib8 trademarks, logos, domain names, other distinctive
              brand features, and other proprietary rights. All right, title,
              and interest in and to the Services (excluding Content provided by
              users) are and will remain the exclusive property of Dib8 and its
              licensors. Any feedback, comments, or suggestions you may provide
              regarding Dib8, or the Services is entirely voluntary and we will
              be free to use such feedback, comments or suggestions as we see
              fit and without any obligation to you. <br /><br />Ending These
              Terms <br />You may end your legal agreement with Dib8 at any time
              by deactivating your accounts and discontinuing your use of the
              Services. <br /><br />We may suspend or terminate your account or
              cease providing you with all or part of the Services at any time
              for any or no reason, including, but not limited to, if we
              reasonably believe: (i) you have violated these Terms or the Dib8
              Rules and Policies, (ii) you create risk or possible legal
              exposure for us; (iii) your account should be removed due to
              unlawful conduct, (iv) your account should be removed due to
              prolonged inactivity; or (v) our provision of the Services to you
              is no longer commercially viable. We will make reasonable efforts
              to notify you by the email address associated with your account or
              the next time you attempt to access your account, depending on the
              circumstances. In all such cases, the Terms shall terminate,
              including, without limitation, your license to use the Services,
              except that the following sections shall continue to apply: 2, 3,
              5, and 6. If you believe your account was terminated in error you
              can file an appeal following the steps found in our Help
              Center(https://help.dib8.app/forms/general?subtopic=suspended).
              For the avoidance of doubt, these Terms survive the deactivation
              or termination of your account.
            </div>
            <div class="tac-section">
              <div class="tac-subtitle">5. Limitations of Liability</div>
              By using the Services you agree that Dib8, its parents,
              affiliates, related companies, officers, directors, employees,
              agents representatives, partners and licensors, liability is
              limited to the maximum extent permissible in your country of
              residence.  
            </div>
            <div class="tac-section">
              <div class="tac-subtitle">6. General</div>
              We may revise these Terms from time to time. The changes will not
              be retroactive, and the most current version of the Terms, which
              will always be at dib8.app/tos, will govern our relationship with
              you. Other than for changes addressing new functions or made for
              legal reasons, we will notify you 30 days in advance of making
              effective changes to these Terms that impact the rights or
              obligations of any party to these Terms, for example via a service
              notification or an email to the email associated with your
              account. By continuing to access or use the Services after those
              revisions become effective, you agree to be bound by the revised
              Terms. <br /><br />In the event that any provision of these Terms
              is held to be invalid or unenforceable, then that provision will
              be limited or eliminated to the minimum extent necessary, and the
              remaining provisions of these Terms will remain in full force and
              effect. Dib8’s failure to enforce any right or provision of these
              Terms will not be deemed a waiver of such right or provision.
              <br /><br />
              These Terms are an agreement between you and Overtex Core
              Solutions SRL (EUID: ROONRCJ12/3380/2022, VAT number RO46260068),
              a Romanian company with its registered office at Alexandru Vaida
              Voievod 59, apartment 115, Cluj, Cluj-Napoca.
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import WelcomeCard from "../components/WelcomeCard.vue";
import Policies from "../components/Policies.vue";
export default {
  components: {
    WelcomeCard,
    Policies,
  },
};
</script>

<style scoped lang="scss">
.left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
}

.mini-container {
  padding-top: 10vh;
}

.mr-16 {
  margin-right: 1rem;
}

.right-container {
  padding: 2rem;
  background-color: #e5f3f7;
  height: 100vh;
  overflow-y: auto;
  font-family: "NunitoLight";
  font-size: 1rem;
  line-height: 1.5rem;
}

.tac-title {
  font-family: "NunitoBold";
  padding-bottom: 2rem;
  font-size: 2.25rem;
  @media (max-width: 600px) {
    font-size: 1.75rem;
  }
}

.tac-section {
  padding-top: 1.75rem;
  padding-bottom: 1.25rem;
}

.tac-subtitle {
  font-family: "NunitoBold";
  font-size: 1.75rem;
  padding-bottom: 0.75rem;
  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
}
</style>

